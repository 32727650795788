import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Icon, Menu, Label } from 'semantic-ui-react';
import omit from 'lodash/omit';

import { __ } from './../i18n';
const styles = {
  specialLabel: {
    backgroundColor: '#0FAD6D',
    height: '25px',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    padding: '5px 3px',
    borderRadius: '3px',
    borderBottomRightRadius: '3px',
    fontWeight: 'bold',
  },
  triangle: {
    width: '0',
    height: '0',
    borderRadius: '12.5px',
    borderTop: '12.5px solid transparent',
    borderBottom: '12.5px solid transparent',
    borderRight: '9px solid #0FAD6D',
    marginRight: '-2px'
  }
}
@inject('store') @observer
export default class MenuView extends React.Component {
  render() {
    const { items, color, store, tabular, id } = this.props;

    const current = this.props.current.replace(/\/$/, '');
    const active = items && items.findIndex(item => item && (item.to === current));

    const menuItems = items && items.map((item, i) => {
      if (!item) return null;

      const style = active === i && !tabular ?
        { borderLeft: `thick solid #${color || store.app.color}` } : {};
      const activeItem = active === i;

      const params = omit(item, ['toParams', 'to', 'name', 'icon', 'iconColor', 'label', 'quantity']);

      if (!activeItem && !!item.to) {
        params.as = Link;
        params.to = item.toParams ? item.to + '?' + item.toParams.join('&') : item.to;
      }

      return (
        <Menu.Item
          key={item.name} active={activeItem} style={{ ...style, display: 'flex' }} id={item.id} data-action={item['data-action']} {...params}
        >
          {typeof item.icon === 'object' ? item.icon : <Icon name={item.icon} color={item.iconColor} />}
          {item.name}
          {item.label ? <Label size="mini" basic color="green" style={{ margin: '0px 0px 0px 8px' }} key={'label_' + item.name}>{item.label}</Label> : null}
          {item.quantity ? <Label circular size="small" color="red" key={'label_' + item.name} style={{ position: 'absolute', right: '8px', bottom: '50%', transform: 'translateY(50%)' }}>{item.quantity}</Label> : null}
          {item.rightIcon === 'object' ? item.rightIcon : <Icon name={item.rightIcon} style={{ margin: '0px 0px 0px 8px', color: '#A5A5A5' }} />}
          {item.new ? (
            <div style={{ position: 'absolute', right: -20, top: 0, height: '100%', display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', width: '100px' }}>
                <div style={styles.triangle} />
                <div style={styles.specialLabel}><Icon name="stars" />{__('NEW!')}</div>
              </div>
            </div>
          ) : null}
        </Menu.Item>);
    });

    const newProps = omit(this.props, ['current', 'items', 'color', 'store', 'id']);

    return (
      <Menu
        id={id}
        style={tabular ? {} : { justifyContent: 'center', marginTop: 0, background: 'white' }}
        {...newProps}
      >
        {menuItems}
      </Menu>
    );
  }
}

import React from 'react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import { Icon, Image } from 'semantic-ui-react';

import SideMenu from './SideMenu';
import ActionButton from './ActionButton';
import Responsive from './Responsive';
import BetaModal from './ui/Modal';
import BetaButton from './ui/Button';
import * as utils from '../utils';
import { __ } from '../i18n';

import { isNetworkRoute } from '../helpers/isNetworkRoute';

const responsive = {
  xs: { minWidth: 0, maxWidth: 576 },
  sm: { minWidth: 576, maxWidth: 768 },
  md: { minWidth: 768, maxWidth: 992 },
  lg: { minWidth: 992, maxWidth: 1200 },
  xl: { minWidth: 1200, maxWidth: 1400 },
  xxl: { minWidth: 1400 }
};

const styles = {
  tabMenu: {
    backgroundColor: 'white',
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '64px',
    zIndex: 999,
    '-webkit-box-shadow': '0px -1px 0px rgba(0, 0, 0, 0.16)',
    '-moz-box-shadow': '0px -1px 0px rgba(0, 0, 0, 0.16)',
    boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.16)'
  },
  tabItem: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 3px'
  },
  tabItemIcon: {
    fontSize: '1.3rem',
    fontWeight: 300,
    marginTop: '5px'
  },
  tabItemText: {
    fontSize: '0.6rem',
    fontWeight: 400,
    marginTop: '4px'
  }
};

@inject('store')
@observer
export default class Container extends Responsive {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: 0
    };
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  }

  // isMobile = () => this.state.screenWidth > responsive.xs.minWidth && window.innerWidth <= responsive.md.maxWidth;

  isDesktop = () => this.state.screenWidth > responsive.md.maxWidth;

  xs = () => this.state.screenWidth > responsive.xs.minWidth && this.state.screenWidth <= responsive.xs.maxWidth;

  sm = () => this.state.screenWidth > responsive.sm.minWidth && this.state.screenWidth <= responsive.sm.maxWidth;

  md = () => this.state.screenWidth > responsive.md.minWidth && this.state.screenWidth <= responsive.md.maxWidth;

  lg = () => this.state.screenWidth > responsive.lg.minWidth && this.state.screenWidth <= responsive.lg.maxWidth;

  xl = () => this.state.screenWidth > responsive.xl.minWidth && this.state.screenWidth <= responsive.xl.maxWidth;

  xxl = () => this.state.screenWidth > responsive.xxl.minWidth;

  renderMenu = (mobileItems, router) => (
    <div style={styles.tabMenu}>
      {mobileItems.map((item) => {
        const pathname = this.props.store.currentLocation.pathname;
        const isActive = (item.to && pathname.includes(item.to)) || (item.default === pathname || item.default + '/' === pathname);

        const activeStyle = {
          ...styles.tabItemIcon,
          color: '#084FFF'
        };

        const disabledTextStyle = {
          ...styles.tabItemText,
          color: item.openPaywall ? '#a6a6a6' : '#000000'
        };

        return (
          <div style={styles.tabItem} onClick={() => { item.openPaywall ? item.openPaywall() : item.onClick ? item.onClick() : this.checkAndRenderWarnModal(item.to); }}>
            <Icon className={`${item.icon} ${isActive ? 'duotone' : ''}`} style={isActive ? activeStyle : styles.tabItemIcon} disabled={item.openPaywall} />
            <span style={disabledTextStyle}>{item.name && item.name.toUpperCase()}</span>
          </div>
        );
      })}
    </div>
  );

  setCookies = (cookieItem) => {
    const { store } = this.props;
    const menuWarnModal = cookie.load('menuWarnModal') || [];
    menuWarnModal.push(cookieItem);
    cookie.save('menuWarnModal', menuWarnModal, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 30 });
  }

  handleOpenLink = (to) => {
    const { router, store } = this.props;
    store.appends.pop();
    router.push(to);
  }

  renderMenuWarning = (to, cookieItem) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ height: '122px', width: '149px', marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Image src={utils.asset('/images/classapp-web.png')} size="medium" style={{ display: 'flex' }} />
      </div>
      <div style={{ marginBottom: '18px', width: '80%', color: 'rgba(29, 29, 29, 1)', fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>{__('Have a better experience using a computer to access')}</div>
      <div style={{ marginBottom: '16px', width: '80%', color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px', textAlign: 'center' }}>{__('Switch to computer to better view your institution\'s data')}</div>
      <BetaButton
        data-action="ok"
        round
        text={__('Ok, I got it')}
        onClick={() => { this.setCookies(cookieItem); this.handleOpenLink(to); this.props.store.appends.pop(); }}
        style={{ marginBottom: '23.5px', width: '90%', height: '47px' }}
      />
    </div>
  );

  checkAndRenderWarnModal = (to) => {
    const menuWarnModal = cookie.load('menuWarnModal') || [];
    let cookieItem = '';
    if (to.includes('organizations')) {
      if (to.includes('messages')) cookieItem = 'messages';
      else if (to.includes('moments')) cookieItem = 'moments';
      else if (to.includes('entities')) cookieItem = 'entities';
      else if (to.includes('channels')) cookieItem = 'channels';
    }
    if (menuWarnModal.includes(cookieItem) || !to.includes('organizations')) this.handleOpenLink(to);
    else {
      this.props.store.appends.push(<BetaModal
        id="OptionsToast"
        toast
        invertCloseButton
        onClickTimesClose={() => { this.setCookies(cookieItem); this.handleOpenLink(to); this.props.store.appends.pop(); }}
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick
        header={__('Warning')}
        spaceFooter
        content={this.renderMenuWarning(to, cookieItem)}
      />);
    }
  };

  render() {
    const { desktopItems, mobileItems, router, id, headerButton, store } = this.props;
    const { currentEntity } = store;
    const pathname = this.props.store.currentLocation.pathname;
    const isEntityMessagePathname = pathname.includes('entities') && pathname.includes('messages');
    const canSendMessage = currentEntity && currentEntity.organization && currentEntity.organization.confMessage &&
      (currentEntity.organization.confMessageStaff || currentEntity.type !== 'STAFF') &&
      (currentEntity.organization.confMessageStudent || currentEntity.type !== 'STUDENT') &&
      (currentEntity.organization.features.messages) &&
      (!currentEntity.disabled);

    const marginRight =
      !this.isMobile() && desktopItems && !isNetworkRoute(pathname)
        ? '7rem'
        : null;

    return (
      <div className="main-layout" id={id}>
        {!this.isMobile() && desktopItems && <SideMenu items={desktopItems} router={router} headerButton={headerButton} />}
        <div
          style={{
            minWidth: '10rem',
            width: '100%',
            transition: '200ms ease',
            marginTop: this.isMobile() ? '1.7rem' : '0rem',
            marginRight,
            marginLeft: !this.isMobile() ? (this.props.store.isMenuExpanded ? null : '3rem') : null
          }}
        >
          {this.props.children}
        </div>
        {this.isMobile() && isEntityMessagePathname && canSendMessage && <ActionButton />}
        {this.isMobile() && this.renderMenu(mobileItems, router)}
      </div>
    );
  }
}

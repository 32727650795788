import React from 'react';
import { render } from '../../../../../../newRender';
import { Table } from './index';

const exampleData = {
  topColumns: [
    { id: '1', label: 'Column 1' },
    { id: '2', label: 'Column 2' },
  ],
  rows: [
    {
      id: 'row1',
      columns: [
        { id: '1', icon: <span data-testid="icon" />, content: 'Item 1' },
        { id: '2', icon: <span />, content: 'Item 2' },
      ],
    },
  ],
};

describe('given Table component', () => {
  it('should be able to find the first item in the first column', () => {
    const { getByText } = render(
      <Table
        topColumns={exampleData.topColumns}
        rows={exampleData.rows}
        isLoading={false}
        onChangeSearchValue={jest.fn()}
      />,
    );

    const firstTableCell = getByText('Item 1');

    expect(firstTableCell).toBeInTheDocument();
  });

  it('should be able to find the second item in the second column', () => {
    const { getByText } = render(
      <Table
        topColumns={exampleData.topColumns}
        rows={exampleData.rows}
        isLoading={false}
        onChangeSearchValue={jest.fn()}
      />,
    );

    const secondTableCell = getByText('Item 2');

    expect(secondTableCell).toBeInTheDocument();
  });

  it('should be able to verify the cell with an icon and without an icon', () => {
    const { getByText, getByTestId } = render(
      <Table
        topColumns={exampleData.topColumns}
        rows={exampleData.rows}
        isLoading={false}
        onChangeSearchValue={jest.fn()}
      />,
    );

    const cellWithIcon = getByText('Item 1').parentElement;
    const icon = getByTestId('icon');
    const cellWithoutIcon = getByText('Item 2').parentElement;

    expect(cellWithIcon).toContainElement(icon);
    expect(cellWithoutIcon).not.toContainElement(icon);
  });

  it('should show loading spinner when loading is set to true', () => {
    const { container, queryByTestId } = render(
      <Table
        topColumns={exampleData.topColumns}
        rows={exampleData.rows}
        isLoading
        onChangeSearchValue={jest.fn()}
      />,
    );

    const loadingSpinner = container.querySelector('svg');

    expect(loadingSpinner).toBeInTheDocument();

    const table = queryByTestId('table');

    expect(table).toBeNull();
  });
});
